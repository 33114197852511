@import '../../Style/sass/core/typography';

.connect-modal {
  &.modal {
    .modal-dialog {
      max-width: 680px;
      .modal-content {
        border: 1px solid rgba(192, 130, 255, 1) !important;
        background: rgba(9, 9, 11, 1);
        position: relative;
        overflow: hidden;

        .bg-blur-grad {
          z-index: 0;
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 300px;
          background: url('../../img/Lightweightimages/wallet-connect-bg-blur.png')
            no-repeat;
          background-size: cover;

          @media screen and (max-width: 767px) {
            height: 200px;
          }
        }
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: rgba(86, 78, 206, 0.08);
    border-radius: 31px 31px 0px 0px;
    padding: 20px 30px;

    svg {
      path {
        fill: rgba(126, 123, 171, 1);
      }
    }

    h4 {
      color: rgba(86, 78, 206, 1);
      font-weight: 700;
      font-size: 26px;
      line-height: 42px;
      margin-bottom: 0;
      font-family: $starstack-font-gemsbuck;
    }

    .modal-close-button {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;

      h4 {
        font-size: 20px;
        line-height: 200%;
      }
    }
  }

  .modal-body {
    padding: 20px 30px;

    .modal-title {
      position: relative;
      z-index: 1;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: #ffffff;

      p {
        color: rgba(126, 123, 171, 1) !important;
        font-size: 18px;
        line-height: 26px;
        span {
          cursor: pointer;
          width: max-content;
          background: linear-gradient(
            to right,
            rgba(86, 78, 206, 1),
            rgba(199, 173, 255, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      span {
        font-weight: 600;
        width: max-content;
        background: linear-gradient(
          to right,
          rgba(255, 184, 154, 1),
          rgba(255, 133, 133, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        &.blue {
          background: linear-gradient(
            to right,
            rgba(199, 173, 255, 1),
            rgba(86, 78, 206, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        &.pink {
          background: linear-gradient(
            to right,
            rgba(255, 184, 154, 1),
            rgba(255, 133, 133, 1),
            rgba(135, 69, 201, 1),
            rgba(169, 79, 176, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      b {
        font-weight: 600;
        width: max-content;
        background: linear-gradient(
          to right,
          rgba(255, 133, 133, 1),
          rgba(169, 79, 176, 1),
          rgba(135, 69, 201, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .modal-form {
      .modal-form-input {
        background: rgba(86, 78, 206, 0.11);
        padding: 20px;
        color: #ffffff;
        border: 1px solid transparent;
        border-radius: 26px;
        &:focus,
        &:hover {
          outline: none;
          box-shadow: none;
          border-color: #564ece;
        }
      }
      .input-info-text {
        display: block;
        color: rgba(126, 123, 171, 1);
        font-size: 15px;
        line-height: 26px;
      }
      .s3-wallet-branding {
        z-index: 1;
        position: relative;
        color: rgba(126, 123, 171, 1);
        font-weight: 500;
        font-size: 21.6px;
        line-height: 24px;
        margin-top: 25px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }

      .modal-update-email-link {
        position: absolute;
        top: 15px;
        right: 10px;
        svg {
          height: 25px;
          width: 25px;
          cursor: pointer;
        }
      }

      .modal-view-password {
        position: absolute;
        top: 15px;
        right: 15px;
        svg {
          height: 25px;
          width: 25px;
          cursor: pointer;
          path {
            fill: #564ece;
          }
        }
      }

      .otp-input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 4%;
        .otp-input {
          flex: 1;
          width: 100%;
          background: none;
          border-radius: 0;
          border: none;
          border-bottom: 2px solid #564ece;
          text-align: center;
          font-size: 24px;
          @media screen and (max-width: 767px) {
            padding: 8px 4px;
          }
        }
      }
    }

    .modal-submit-button {
      z-index: 1;
      position: relative;
      background-color: #564ece;
      border-color: #564ece;
      min-width: 50%;
      font-family: $starstack-font-gemsbuck;

      &:hover {
        background-color: transparent;
      }
    }

    .private-key-modal {
      h4 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 130%;
        span {
          background: linear-gradient(
            90deg,
            #ffb89a -27.42%,
            #ff8585 3.49%,
            #8745c9 37.06%,
            #a94fb0 37.07%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      p {
        color: #7e7bab;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        span {
          color: #fff;
          font-weight: 700;
        }
      }

      h6 {
        color: #fff;
        font-family: $starstack-font-gemsbuck;
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
      }

      .private-key-display {
        border-radius: 6.5px;
        background: #24223d;
        padding-left: 24px;
        display: flex;
        align-items: center;
        gap: 12px;
        span {
          color: #fff;
        }
        .eye-icon {
          svg {
            height: 25px;
            width: 25px;
            cursor: pointer;
            path {
              fill: #564ece;
            }
          }
        }
        .copy-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 10px;
          border-radius: 6.5px;
          background: #4d498a;
          height: 100%;
          cursor: pointer;
          svg {
            height: 25px;
            width: 25px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .quick-actions-modal {
      padding: 0 40px;
      h4 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 130%;
        span {
          background: linear-gradient(218deg, #564ece 0%, #c7adff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .teleport-desc-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 130%;
        color: #7e7bab !important;
        width: 80%;
        margin: auto;
        text-align: center;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      button {
        font-weight: 500;
        font-size: 20px;
        text-align: left;
        padding: 10px 20px;
        width: 100%;
        color: #7e7bab;

        &:hover {
          background: linear-gradient(
            100.14deg,
            #22bae6 -133.57%,
            #6e53e3 87.51%
          );
          color: rgba(255, 255, 255, 0.79);
        }

        svg {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          @media screen and (max-width: 767px) {
            margin-right: 0;
          }
        }

        @media screen and (max-width: 767px) {
          min-width: 100%;
          padding: 8px 14px;
          font-size: 17px;
          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
      .text-hover-underline:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 16px;

      .modal-title {
        font-size: 20px;
      }

      .quick-actions-modal {
        padding: 0 14px;
      }
    }
  }

  .location-upload-modal-content {
    .form-title {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 1);
      font-size: 21px;
      line-height: 38px;
      font-weight: 700;
      font-family: $starstack-font-gemsbuck;
    }
    .form-desc {
      color: rgba(126, 123, 171, 1) !important;
      line-height: 21px;
    }
    .location-inputs-wrapper {
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;

      .country-select-input {
        .react-select__control {
          width: 100%;
          height: 65px;
          border-radius: 36.5px;
          font-weight: 500;
          font-size: 16px;
          background-color: rgba(86, 78, 206, 0.08);
          .react-select__single-value {
            padding-left: 15px;
            color: white;
          }
        }
      }

      .form-control {
        border: none;
        background-color: rgba(86, 78, 206, 0.08);
        height: 65px;

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }

      input {
        color: white;
      }

      .city-state-text {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 6px;
        .tick-icon {
          background: linear-gradient(100.7deg, #9381f5 31.46%, #4c45b9 86.53%);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        span {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          line-height: 110%;
        }
      }
    }
    .checkbox-text-wrapper {
      display: flex;
      gap: 15px;
      margin-top: 40px;

      input {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-radius: 0;
        border: 1px solid #564ece;
        &:active,
        &:focus {
          box-shadow: none;
        }
      }
      p {
        color: rgba(126, 123, 171, 1) !important;
      }
    }
  }
  .later-text-btn {
    padding: 12px 28px;
    text-decoration: underline;
    margin-bottom: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .display-name-upload-modal-content {
    z-index: 1;
    position: relative;
    .form-title {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 1);
      font-size: 21px;
      line-height: 38px;
      font-weight: 700;
      font-family: $starstack-font-gemsbuck;
    }
    .form-desc {
      color: rgba(126, 123, 171, 1) !important;
      line-height: 21px;
    }
    .display-name-inputs-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;

      @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }

      input {
        color: white;
      }
    }
    .profile-picture-main-content {
      margin-top: 40px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      gap: 25px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      button {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        background: #564ece;
        color: white;
        border: 1px solid #564ece;

        &:hover {
          color: #564ece;
          border-color: #564ece;
          background: transparent;
          text-decoration: none;
        }

        &.remove-button {
          background: transparent;
          border: 1px solid #ff3f56;

          &:hover {
            color: #ff3f56;
            border-color: #ff3f56;
            opacity: 0.8;
          }
        }
      }

      .default-profile-picture-display {
        width: 93px;
        height: 93px;
        border-radius: 50%;
        background-color: rgba(86, 78, 206, 0.22);
      }
      .user-profile-picture-display {
        position: relative;
        width: 93px;
        height: 93px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .loader-svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
