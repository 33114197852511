@import '../../Style/sass/core/typography';

.sandbox-page-wrapper {
  position: relative;
  background-image: url(./bg-main.webp);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;

  .header-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(28px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .content-container {
      display: flex;
      align-items: center;
      padding: 2vmin 4vmax;
      gap: 2vmax;
      @media screen and (max-width: 991px) {
        padding: 2% 4%;
      }
      @media screen and (max-width: 600px) {
        padding: 2%;
      }
      .logo-container {
        svg {
          width: 200px;
          height: auto;
        }
        @media screen and (max-width: 1400px) {
          svg {
            width: 151px;
            height: 22px;
            margin-top: -8px;
          }
        }
        @media screen and (max-width: 991px) {
          height: 20px;
          width: auto;
        }
      }
      .text-container {
        color: #9f99ff;
        font-size: 23px;
        font-weight: 300;
        line-height: 170%;
        span {
          color: #fff;
        }
        @media screen and (max-width: 1400px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1240px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1140px) {
          font-size: 15px;
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      .btn-container {
        .btn {
          background: linear-gradient(180deg, #564ece 0%, #6036ff 100%);
          border-radius: 100px;
          padding: 8px 28px;
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
          width: max-content;
          &:hover {
            background: transparent;
          }
          @media screen and (max-width: 1240px) {
            font-size: 15px;
          }
          @media screen and (max-width: 767px) {
            padding: 6px 18px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .main-section {
    min-height: 100vh;
    min-height: 100svh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 4%;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 4%;
    }
    @media screen and (max-width: 600px) {
      padding: 2% 2% 4%;
    }
    .first-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 991px) {
        justify-content: flex-end;
        height: 100%;
      }
      .logo-container {
        svg {
          height: 75px;
          width: auto;
          @media screen and (max-width: 1400px) {
            height: 65px;
          }
          @media screen and (max-width: 1240px) {
            height: 55px;
          }
          @media screen and (max-width: 991px) {
            width: 90%;
            height: auto;
          }
        }
      }
      .text-container {
        margin-top: 30px;
        margin-bottom: 20px;
        h1 {
          color: #fff;
          font-family: $starstack-font-gemsbuck;
          font-size: 40px;
          font-weight: 700;
          line-height: 110%;
          @media screen and (max-width: 1400px) {
            font-size: 32px;
          }
          @media screen and (max-width: 1240px) {
            font-size: 26px;
          }
          @media screen and (max-width: 991px) {
            font-size: 22px;
          }
        }
        p {
          color: #756edb;
          font-size: 28px;
          font-weight: 400;
          line-height: 120%;
          @media screen and (max-width: 1400px) {
            font-size: 24px;
          }
          @media screen and (max-width: 1240px) {
            font-size: 20px;
          }
          @media screen and (max-width: 991px) {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
      .btn-container {
        .btn {
          background: linear-gradient(180deg, #564ece 0%, #6036ff 100%);
          border-radius: 100px;
          padding: 12px 36px;
          font-size: 28px;
          font-weight: 700;
          line-height: 140%;
          width: max-content;
          &:hover {
            background: transparent;
          }
          @media screen and (max-width: 1400px) {
            font-size: 24px;
          }
          @media screen and (max-width: 1240px) {
            font-size: 20px;
          }
          @media screen and (max-width: 991px) {
            font-size: 16px;
            padding: 9px 26px;
          }
        }
      }
      .bottom-section {
        margin-top: 15%;
        margin-bottom: -15%;
        display: flex;
        align-items: center;
        backdrop-filter: blur(14.5px);
        background: rgba(0, 0, 0, 0.18);
        width: 80%;
        min-width: max-content;
        @media screen and (max-width: 991px) {
          margin-bottom: 0;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .text-section {
          flex: 4;
          padding: 0 20px;
          h5 {
            color: #fff;
            font-family: $starstack-font-gemsbuck;
            font-size: 32px;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 0;
            @media screen and (max-width: 1400px) {
              font-size: 25px;
            }
            @media screen and (max-width: 1240px) {
              font-size: 20px;
            }
            @media screen and (max-width: 991px) {
              font-size: 16px;
            }
          }
          span {
            color: rgba(255, 255, 255, 0.7);
            font-size: 23px;
            font-weight: 400;
            line-height: 100%;
            @media screen and (max-width: 1400px) {
              font-size: 20px;
            }
            @media screen and (max-width: 1240px) {
              font-size: 16px;
            }
            @media screen and (max-width: 991px) {
              font-size: 13px;
            }
          }
        }
        .icon-section {
          padding: 40px 0;
          flex: 1;
          background: #0000002e;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            height: 50px;
            width: auto;
            transition: all 0.2s ease-in-out;
          }
          &:hover svg {
            transform: scale(1.2);
          }
          @media screen and (max-width: 1400px) {
            padding: 30px;
            svg {
              height: 40px;
            }
          }
          @media screen and (max-width: 1240px) {
            padding: 32px 18px;
            svg {
              height: 32px;
            }
          }
        }
      }
    }
    .second-section {
      height: 100%;
      .second-section-img {
        height: 100%;
        background-image: url(./bg-cards.webp);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
