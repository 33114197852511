.stake-hub {
  .right-wrapper {
    .blurr-bg {
      &::after {
        content: '';
        background-image: url($aws-base-url + '/common/blur-bg.webp');
        opacity: 0.5;
        top: 15%;
        left: 256px;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }

  .sec-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    color: $white-color;
  }

  .royalty-wallet-charts {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .box {
    // min-height: 200px;
    // padding: 1rem 2rem;
    background: #0b0c11;
    overflow: hidden;
    box-shadow: 0px 14px 34px rgba(21, 17, 28, 0.35);
    border-radius: 12px;
  }

  @media screen and (max-width: 600px) {
    .royalty-wallet-charts {
      flex-direction: column;
    }
  }

  &.sidenav-collapsed {
    overflow-x: visible;
    .stakehub-sidenav-wrapper {
      width: 100px;
      .MuiPaper-root {
        overflow: visible;
      }
      .link-list {
        overflow: visible;
        .sub-dropdown-wrapper {
          position: absolute;
          top: -60px;
          left: 90px;
          z-index: 999999;
          width: 0;
          overflow: visible;
          a {
            background-color: #1e1f24;
            min-width: 200px;
            width: max-content;
          }
        }
      }
    }
  }
}

.pie-chart.stakehub-bals {
  .pai-chart {
    margin-top: -30px;
  }

  .chart-heading h3 {
    text-transform: uppercase;
    margin-top: 10px;
  }

  .chart-pie-text {
    p {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: -0.03em;
      color: rgba(183, 189, 212, 0.65);
    }
  }

  .pie-chart-holder .bottom.bottoms {
    bottom: -38px;
  }

  .pie-chart-holder .bottom .info .per {
    font-weight: 300;
    font-size: 14.1521px;
    letter-spacing: -0.03em;
    color: $casper-blue-color;
    padding: 0.3rem 0.7rem;

    span {
      font-weight: 400;
    }
  }
}

.card-bg-gray {
  background: rgba(239, 233, 255, 0.1) !important;
}

@media screen and (max-width: 600px) {
  .right-info {
    svg {
      width: 100px;
      height: 30px;
    }
  }
}

//stakehub sidenav
.stakehub-side-navbar {
  color: #808191;
  font-weight: 600;
  font-size: 18px;

  .MuiPaper-root {
    background-color: transparent;
    color: #808191;
    font-weight: 600;
    font-size: 18px;
    .MuiAccordionSummary-root {
      border-radius: 12px;
      &:hover {
        background-color: rgba(228, 228, 228, 0.1);
      }
    }
    a {
      color: #808191;
      font-weight: 600;
      font-size: 18px;
      text-decoration: none;
    }
  }
  .stakehub-sidenav-wrapper {
    padding: 0 5px !important;
    .title {
      width: 100%;
      font-weight: 600;
      font-size: 21px;
      margin-top: 5vh;
      margin-bottom: 3vh;
      display: flex;
      align-items: center;
      min-height: 60px;
      padding: 0 16px;
    }
    .creation-title {
      background: linear-gradient(
        89.93deg,
        #ffb89a 12.96%,
        #ff8585 54.64%,
        #8745c9 99.91%,
        #a94fb0 99.92%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .stakehub-title {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .link-list {
      height: calc(100% - 8vh - 60px);
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .right-arrow {
        transform: rotate(270deg);
      }
      svg {
        height: 24px;
        width: 24px;
      }
      .grey-fill {
        svg {
          path {
            fill: #808191;
          }
        }
      }
      .active {
        color: #564ece;
        svg {
          path {
            fill: #564ece;
          }
        }
      }
      .active-stroke svg {
        path,
        circle {
          stroke: #564ece;
        }
      }
      .home-tab {
        cursor: pointer;
        border-radius: 12px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        min-height: 48px;
        &:hover {
          background-color: rgba(228, 228, 228, 0.1);
        }
      }
      .home-tab-disabled {
        cursor: not-allowed;
        background-color: rgba(0, 0, 0, 0.12);
        padding: 0 16px;
        display: flex;
        align-items: center;
        min-height: 48px;
      }
      .sub-dropdown-wrapper {
        width: 200px;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        background: rgba(228, 228, 228, 0.1);
        mix-blend-mode: normal;
        border-radius: 7px;
        overflow: hidden;
        a {
          padding: 10px 20px;
          &:hover {
            background: #564ece;
            color: white;
          }
        }
      }
    }
    .quick-tour-container {
      margin-bottom: 12px;
      margin: auto auto 16px;
      width: calc(100% - 24px);
      background-image: url(../../../Components/StakeHub/Home/QuickTour/stakehub-tour-bg.webp);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 14px;
      &.creatorhub-tour-card {
        background-image: url(../../../Components/StakeHub/Home/QuickTour/creatorhub-tour-bg.webp);
      }
      .details-container {
        border-radius: 14px;
        padding: 18px 12px;
        width: 100%;
        aspect-ratio: 0.86;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-color: rgba(0, 0, 0, 0.3);
        h2 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          line-height: 120%;
          margin-bottom: 12px;
          span {
            background: var(
              --Linear,
              linear-gradient(
                90deg,
                #ffb89a 12.96%,
                #ff8585 54.64%,
                #8745c9 99.91%,
                #a94fb0 99.92%
              )
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .btn {
          background-color: #fff;
          border-color: #fff;
          border-radius: 24px;
          padding: 12px;
          width: 100%;
          color: #564ece;
          font-size: 14px;
          line-height: 130%;
          &:hover {
            background-color: transparent;
            color: #fff;
          }
        }
      }
    }
    .logout-tab {
      cursor: pointer;
      position: fixed;
      bottom: 3vh;
      left: 5px;
      width: 259px;
      border-radius: 12px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      min-height: 48px;
      svg {
        height: 24px;
        width: 24px;
        path {
          fill: #808191;
        }
      }
      &:hover {
        background-color: rgba(228, 228, 228, 0.1);
      }
    }
    .logout-tab-disabled {
      cursor: not-allowed;
      background-color: rgba(0, 0, 0, 0.12);
      position: fixed;
      bottom: 3vh;
      left: 5px;
      width: 259px;
      border-radius: 12px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      min-height: 48px;
      svg {
        height: 24px;
        width: 24px;
        path {
          fill: #808191;
        }
      }
    }
  }
}
