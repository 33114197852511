.mg-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .mg-card{
        width: 379px;
        height: 223px;
        margin: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 21px;
        &:hover {
            background: linear-gradient(126.44deg, #564ECE 3.16%, #8367EE 77.34%);
        }

        .mg-h1{
            font-family: Poppins;
            font-size: 30px;
            line-height: 33px;
            font-weight: 600;
            color: white;
        }
        .mg-h2{
            font-family: Poppins;
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}